import NavBar from "./components/NavBar.js";
import About from "./components/About.js";
import Banner from "./components/Banner.js";
import Projects from "./components/Projects.js";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <NavBar name="DanFMN" />
      </header>
      <Banner />
      <About />
      <hr />
      <Projects />
      <hr />
    </div>
  );
}

export default App;
