import React from "react";
import "./Banner.css";

import banner from "../assets/banner.jpg";
import headshot from "../assets/headshot.jpg";
import github from "../assets/github.svg";
import linkedin from "../assets/linkedin.svg";
import youtube from "../assets/youtube.svg";
// import discord from '../assets/discord.svg'

var sectionStyle = {
  backgroundImage: `url(${banner})`,
};

class Banner extends React.Component {
  render() {
    return (
      <div
        style={sectionStyle}
        src={banner}
        className="App-banner"
        alt="banner"
      >
        <section className="Banner-container">
          <img src={headshot} className="Banner-headshot" alt="headshot" />
          <h2>Dan Foreman</h2>
          <h3>Software Engineer</h3>
          <div className="Socials-container">
            <a
              href="https://github.com/danfmn"
              target="_blank"
              rel="noreferrer"
            >
              <img src={github} alt="github" />
            </a>
            <a
              href="https://www.linkedin.com/in/daniel-fmn/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={linkedin} alt="linkedin" />
            </a>
            {/*<a href="https://discord.gg/cC9KZUd" target="_blank" rel="noreferrer">
              <img src={discord} alt="discord" />
    </a>*/}
            <a
              href="https://youtube.com/danfmn"
              target="_blank"
              rel="noreferrer"
            >
              <img src={youtube} alt="youtube" />
            </a>
          </div>
        </section>
      </div>
    );
  }
}

export default Banner;
