import React from "react";
import "./Projects.css";
import Project from "./Project.js";
function Projects() {
  return (
    <section id="Projects">
      <h2>PORTFOLIO</h2>
      <div className="Projects-container">
        <Project
          className="Project"
          projectData={{
            name: "YourDevTools.com",
            date: 2021,
            image: "yourdevtools",
            project: "https://yourdevtools.com",
            description:
              "Website that features a variety of tools meant to support development such as Game Server generated code snippets for games like Rust, Minecraft and Garry's Mod. YourDevTools uses TailwindCSS, Next.js, React, Google Tag Manager & Analytics.",
          }}
        />
        <hr />
        <Project
          className="Project"
          projectData={{
            name: "React Episode Switcher",
            date: 2021,
            image: "episodeswitcher",
            project: "https://danfmn.github.io/react-episode-switcher/",
            code: "https://github.com/danfmn/react-episode-switcher",
            description:
              "This website was made to showcase my skills formatting and manipulating data from the TVMaze API. It's built using React. Episodes are received in a flat structure and split into seasons / season episodes.",
          }}
        />
        <hr />
        <Project
          className="Project"
          projectData={{
            name: "Vue Inquiry Form Template",
            date: 2021,
            image: "formtemplate",
            project: "https://danfmn.github.io/vue-form-template/",
            code: "https://github.com/danfmn/vue-form-template",
            description:
              "This website was built to be used as a template for inquiry forms. It's built using Vue. It has support for dynamic form fields, clientside validation and is a SPA.",
          }}
        />
        <hr />
        <Project
          className="Project"
          projectData={{
            name: "DConfig",
            date: 2018,
            image: "dconfig",
            project:
              "https://www.gmodstore.com/market/view/dconfig-in-game-darkrp-customization-jobs-shipments-categories-etc",
            description:
              "DConfig is a Garry's Mod plugin that is built on top of the DarkRP game. It's a tool that allows you to configure the game through a UI instead of touching the game's lua code. This plugin has been purchased over 1,450 times.",
          }}
        />
        <hr />
        <Project
          className="Project"
          projectData={{
            name: "DWeapons",
            date: 2018,
            image: "dweapons",
            project:
              "https://www.gmodstore.com/market/view/dweapons-in-game-weapon-customization",
            description:
              "DWeapons is Garry's Mod plugin that allows users to edit weapon data through a UI. It's built to support any scripted weapons structure out of the box. You can change almost any weapon property. This plugin has been purchased over 600 times.",
          }}
        />
        <hr />
        <Project
          className="Project"
          projectData={{
            name: "DRadio",
            date: 2018,
            image: "project",
            project:
              "https://www.gmodstore.com/market/view/dradio-team-channel-and-frequency-communication",
            description:
              "DRadio is a plugin developed for garry's mod that gives users access to voice chat over user defined frequencies. It has been purchased over 1,200 times.",
          }}
        />
        <hr />
        <Project
          className="Project"
          projectData={{
            name: "DInventory",
            date: 2018,
            image: "dinventory",
            project:
              "https://www.gmodstore.com/market/view/dinventory-hotbar-player-preview-easy-installation",
            description:
              "DInventory is an inventory system built for Garry's Mod. It has been built to support JSON or MySQL database saving. It's built using a relational database for items and players. It has been purchased over 360 times.",
          }}
        />
      </div>
    </section>
  );
}

export default Projects;
