import React from "react";
import "./Button.css";

function StyledButton(props) {
  return (
    <>
      {props.href && (<a
        href={props.href}
        target="_blank"
        className={["Button-container", props.className].join(" ")}
        rel="noreferrer"
      >
        {props.text}
      </a>)}
      {!props.href && (
        <div target="_blank"
          className={["Button-container", props.className].join(" ")}
          rel="noreferrer">
          {props.text}
        </div>
      )}
    </>
  );
}

export default StyledButton;
