import React from "react";
import "./Project.css";
import StyledButton from "./Button.js";

function ProjectInfo(props) {
  return (
    <div className="Project-info">
      <h3>{props.title}</h3>
      <p className="Project-year">{props.date}</p>
      <p>{props.description}</p>
    </div>
  );
}

function ProjectImage(props) {
  const image = require("../assets/projects/" +
    (props.image || "project") +
    ".png").default;
  return (
    <div>
      <img src={image} className="Project-image" alt="Project" />
    </div>
  );
}

function Project(props) {
  return (
    <div className="Project">
      <div className="Project-col-left">
        <ProjectImage image={props.projectData.image} />
      </div>
      <div className="Project-col-right">
        <ProjectInfo
          title={props.projectData.name}
          date={props.projectData.date}
          description={props.projectData.description}
        />
        <div className="Project-buttons">
          {props.projectData.project && (
            <StyledButton
              className="Project-button"
              text="View Project"
              href={props.projectData.project}
            />
          )}
          {props.projectData.code && (
            <StyledButton
              className="Project-button"
              text="View Code"
              href={props.projectData.code}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Project;
