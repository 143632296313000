import React, { useEffect, useState } from "react";
import "./NavBar.css";
import arrow from "../assets/up-arrow.svg";

function NavBar(props) {
  const [sticky, setSticky] = useState(false);
  const [hasArrow, setArrow] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    function onScroll(e) {
      setScrollTop(e.target.documentElement.scrollTop);
      setSticky(e.target.documentElement.scrollTop > 40);
      setArrow(
        window.innerWidth < 800 && e.target.documentElement.scrollTop > 518
      );
    }
    window.addEventListener("scroll", onScroll);

    return function cleanUp() {
      window.removeEventListener("scroll", onScroll);
    };
  }, [scrollTop]);

  function scrollToID(id) {
    const element = document.getElementById(id);
    if (!element) return;
    const yOffset = -50;
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: "smooth" });
  }

  return (
    <>
      <section className={"NavBar " + (sticky ? "NavBar-sticky" : "")}>
        <h2>Dan Foreman</h2>
        <ul className="NavBar-menu">
          <li onClick={() => scrollToID("root")}>Home</li>
          <li onClick={() => scrollToID("About")}>About</li>
          <li onClick={() => scrollToID("Projects")}>Projects</li>
        </ul>
      </section>
      <div
        onClick={() => scrollToID("root")}
        id="top-arrow"
        style={{ opacity: hasArrow ? "100" : "0" }}
      >
        <img src={arrow} alt="up arrow" />
      </div>
    </>
  );
}

export default NavBar;
