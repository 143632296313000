import React from "react";
import "./About.css";
import aboutImage from "../assets/about.jpg";

class About extends React.Component {
  render() {
    return (
      <section id="About">
        <h2>ABOUT ME</h2>
        <div className="About-row">
          <div className="About-col">
            <h3>I'm a Software Engineer.</h3>
            <p>
              I've been in love and inspired by technology since I was little. I
              dreamed about taking all the ideas in my head and sharing them
              with the world through technology. My software development career
              sparked from writing video game mods, a lot of them which you'll
              see here.
            </p>
            <p>
              In my spare time you'll find me working on my next big idea or
              spending time catering to my new lovely garden. My ideas typically
              involve problem solving. I'm always looking for a way to improve
              the lives of others with my creations.
            </p>
            <p>
              In order to keep up with the rapidly changing technological world,
              I keep myself busy tinkering and trying out new languages and
              frameworks that are available. As of now I feel confident enough
              to program in at least 6 different languages.
            </p>
          </div>
          <div className="About-col">
            <img className="About-image" src={aboutImage} alt="About Me" />
          </div>
        </div>
      </section>
    );
  }
}

export default About;
